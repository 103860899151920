import { IconAndTitleWithContent } from "@/components/Helpers/IconAndTitleWithContent";
import React, { Component, ReactNode } from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation]);

interface OurServicesState {
  sliderPerView: number;
  isUnMounted: boolean;
}

interface OurServicesProps {
  children?: ReactNode;
  services: any;
}

export default class OurServices extends Component<OurServicesProps, OurServicesState> {
  constructor(props: OurServicesProps) {
    super(props);

    this.state = {
      sliderPerView: 0,
      isUnMounted: false,
    };
  }

  handleSlidesPerView(currentWidth: number): number {
    if (currentWidth > 1198) {
      return 3;
    }
    if (currentWidth < 1198 && currentWidth > 998) {
      return 2;
    }
    if (currentWidth < 998 && currentWidth > 580) {
      return 1;
    }

    return 1;
  }

  componentDidMount(): void {
    this.setState({
      isUnMounted: false,
    });

    if (this.state.isUnMounted) {
      return;
    }

    if (typeof window !== "undefined") {
      this.setState({
        sliderPerView: this.handleSlidesPerView(window.innerWidth),
      });
      window.addEventListener("resize", () => {
        if (this.state.isUnMounted) {
          return;
        }
        this.setState({
          sliderPerView: this.handleSlidesPerView(window.innerWidth),
        });
      });
    }
  }

  componentWillUnmount(): void {
    if (typeof window !== "undefined") {
      this.setState({
        isUnMounted: true,
      });

      window.addEventListener("resize", () => {
        this.setState({
          isUnMounted: true,
        });
      });
    }
  }

  render(): JSX.Element {
    const { ourServices } = this.props.services;

    return (
      <section className="section section-md bg-gray-3 text-center">
        <div className="container">
          <h3 className="offset-top-7 font-weight-regular">Our Services</h3>

          <Swiper
            slidesPerView={this.state.sliderPerView}
            spaceBetween={40}
            className="pt-5 pb-3 pl-2 pr-1"
          >
            {ourServices?.map(
              ({
                id,
                title,
                description,
                icon: {
                  file: { url: serviceIcon },
                },
              }) => {
                return (
                  <SwiperSlide className="swipers-article" key={id}>
                    <IconAndTitleWithContent
                      iconURL={serviceIcon}
                      alt={title}
                      title={title}
                      content={description}
                    />
                  </SwiperSlide>
                );
              },
            )}
          </Swiper>
        </div>
      </section>
    );
  }
}
