import Layout from "@/components/Layout";
import SEO from "@/components/SEO";
import { EmbeddedAssetBlockImageUi } from "@/components/Shared/RichtextUi/EmbeddedAsset";
import { EmbeddedBlockUi } from "@/components/Shared/RichtextUi/EmbeddedBlockUi";
import {
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "@/components/Shared/RichtextUi/Headings";
import { ParagraphWide } from "@/components/Shared/RichtextUi/ParagraphWide";
import { StructuredDataSnippetTag } from "@/components/Shared/StructuredDataSnippetTag";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import React from "react";
import Banner from "./Banner";
import OurServices from "./OurServices";
import PopularQuestion from "./PopularQuestions";

export const query = graphql`
  query SolutionDetailPage($slug: String!) {
    contentfulSolutionModel(slug: { eq: $slug }) {
      detailedPage {
        seo {
          documentTitle
          metaTitle
          metaUrl
          metaAuthor
          description {
            description
          }
          metaKeywords
        }

        #     Banner
        banner {
          id
          bannerTitle {
            title
            sub_title
          }
          bannerContent {
            bannerContent
          }
          bannerImage {
            file {
              url
            }
          }
        }

        #     Services
        ourServices {
          ourServices {
            id
            title
            description
            icon {
              file {
                url
              }
            }
          }
        }

        content {
          raw
        }

        #     FAQs
        popularQuestions {
          faQs {
            id
            question
            answer {
              answer
            }
          }
        }

        footerData {
          content {
            raw
          }
        }

        # Structured snippet
        structuredDataSnippets {
          id
          snippet {
            snippet
          }
        }
      }
    }
  }
`;

interface SolutionPageProps {
  data: {
    contentfulSolutionModel: {
      detailedPage: {
        seo: any;
        footerData: any;
        ourServices: any;
        banner: any;
        popularQuestions: any;
        structuredDataSnippets: any;
        content: any;
      };
    };
  };
}

export default class Index extends React.PureComponent<SolutionPageProps> {
  private provideOptions(): Options {
    const options: Options = {
      renderNode: {
        [BLOCKS.DOCUMENT]: (node, children) => (
          <div className="container blog-container grid-demo grid-demo-underlined">{children}</div>
        ),
        [BLOCKS.PARAGRAPH]: (_, children) => <ParagraphWide paragraph={children} />,
        [BLOCKS.HEADING_1]: (_, children) => <HeadingOne heading={children} />,
        [BLOCKS.HEADING_2]: (_, children) => <HeadingTwo heading={children} />,
        [BLOCKS.HEADING_3]: (_, children) => <HeadingThree heading={children} />,
        [BLOCKS.HEADING_4]: (_, children) => <HeadingFour heading={children} />,
        [BLOCKS.HEADING_5]: (_, children) => <HeadingFive heading={children} />,
        [BLOCKS.HEADING_6]: (_, children) => <HeadingSix heading={children} />,
        "embedded-entry-block": (node) => (
          <EmbeddedBlockUi
            node={node}
            references={this.props.data.contentfulSolutionModel.detailedPage.content?.references}
          />
        ),
        "embedded-asset-block": (node) => (
          <EmbeddedAssetBlockImageUi
            node={node}
            references={this.props.data.contentfulSolutionModel.detailedPage.content?.references}
          />
        ),
      },
    };
    return options;
  }

  render(): JSX.Element {
    const { banner, ourServices, popularQuestions, seo, footerData, structuredDataSnippets } =
      this.props.data.contentfulSolutionModel.detailedPage;

    const {
      description: { description },
      documentTitle,
      metaKeywords,
      metaTitle,
      metaUrl,
      metaAuthor,
    } = seo;

    return (
      <Layout contentfulFooterContentForPages={footerData}>
        <SEO
          contentfulSeo={{
            documentTitle,
            metaTitle,
            metaDescription: description,
            metaKeywords,
            metaUrl,
            metaAuthor,
          }}
        />
        <StructuredDataSnippetTag snippets={structuredDataSnippets} />
        <Banner banner={banner} />
        <OurServices services={ourServices} />
        <div>
          {documentToReactComponents(
            JSON.parse(this.props.data.contentfulSolutionModel.detailedPage.content.raw),
            this.provideOptions(),
          )}
        </div>
        <PopularQuestion faqs={popularQuestions.faQs} />
      </Layout>
    );
  }
}
